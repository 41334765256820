const idPortalApi = "9a9a20ec72b6638814b9d8f6b0c35ac7b612da9d";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.cmbrejodeareia.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmbrejodeareia.ma.gov.br",
  NomePortal: "Brejo de Areia",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 12, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/krNCXddUh5g7zZHKA`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10460.293641678933!2d-45.58681030438732!3d-4.33104101129806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92cb0b4b90af1a0b%3A0x84488e8023a3eb6c!2sBrejo%20de%20Areia%2C%20State%20of%20Maranh%C3%A3o%2C%2065390-000!5e0!3m2!1sen!2sbr!4v1730817928529!5m2!1sen!2sbr",
};
