import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import api from "../../../../services/api";
import "../../../../styles/banner.css";
import { imagemURL, TipoPortal } from "../../../../services/variables";
import SubTitle from "../title/SubTitle";

export default function CarouselBienios() {
  const [banner, setBanner] = useState([]);

  const dateRender = (dateReq) => {
    const date = new Date(dateReq);
    if (dateReq == "") {
      return "";
    } else {
      date.setDate(date.getDate() + 1);
      const formatDate = date.getFullYear();
      return formatDate;
    }
  };

  useEffect(() => {
    api
      .get(`/gallery/list/available/2/1`)
      .then((res) => {
        if (!res.data.err) {
          console.log("aqui");
          setBanner(res.data.res);
          console.log(banner);

          //setRemoveLoading(true)
          //console.log(res.data.res[0])
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      {banner.length != 0 && (
        <>
          <SubTitle subTitle={"Galeria"} border={true} />
          <div className="section-title">
            <Carousel touch={true} className="cardCarouselOffice">
              {banner?.map((item, i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      className="d-block w-200 imgCarouselOffice"
                      src={`${imagemURL + item.IMG}`}
                      alt="Imagem Galeria"
                    />
                    <Carousel.Caption className="carouselOffice">
                      <h6>{item.NOME}</h6>
                      <p>
                        Legislatura {dateRender(item["DATA_INICIO"])} a{" "}
                        {dateRender(item["DATA_FIM"])}
                      </p>
                      <a
                        title="Acesse o Histórico"
                        className="btn-banner-link"
                        variant="primary"
                        href={
                          TipoPortal == 3
                            ? "/informacoes-institucionais/mural/historico"
                            : "/legislativo-municipal/mural/historico"
                        }
                      ></a>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
}
