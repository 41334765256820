import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import api from "../../../../services/api";
import "../../../../styles/banner.css";
import { imagemURL } from "../../../../services/variables";
import styled from "../../../../styles/module/transparency/bienios.module.css";
import Loading from "../Loading";
import SubTitle from "../title/SubTitle";

export default function HistoryBienios() {
  const [banner, setBanner] = useState([]);
  const [removeLoading, setRemoveLoading] = useState(false);

  const dateRender = (dateReq) => {
    const date = new Date(dateReq);
    if (dateReq == "") {
      return "";
    } else {
      date.setDate(date.getDate() + 1);
      const formatDate = date.getFullYear();
      return formatDate;
    }
  };

  useEffect(() => {
    api
      .get(`/gallery/list/available/2/1`)
      .then((res) => {
        if (!res.data.err) {
          setBanner(res.data.res);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setRemoveLoading(true);
      });
  }, []);

  return (
    <>
      {!removeLoading && <Loading />}
      {banner.length != 0 && (
        <div className="section-title">
          <SubTitle subTitle={"Galeria"} border={true} />

          <div className={styled.cardGrup}>
            {banner?.map((item, i) => (
              <div className={styled.item} key={i}>
                <div className={styled.dateCard}>
                  <span className={styled.dateText}>
                    Biênio {dateRender(item["DATA_INICIO"])} a{" "}
                    {dateRender(item["DATA_FIM"])}
                  </span>
                </div>
                <div className={styled.titleCard}>
                  <img
                    className={styled.imgBienios}
                    src={`${imagemURL + item.IMG}`}
                    alt="Imagem Biênios"
                  />
                  <p
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styled.titleText}
                  >
                    {item.NOME}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <Carousel touch={true} className="cardCarouselOffice">
            {banner?.map((item, i) => {
              return (
                <Carousel.Item key={i}>
                  <img
                    className="d-block w-200 imgCarouselOffice"
                    src={`${imagemURL + item.IMG}`}
                    alt="Imagem Galeria"
                  />
                  <Carousel.Caption className="carouselOffice">
                    <h6>{item.NOME}</h6>
                    <p>
                      Legislatura {dateRender(item["DATA_INICIO"])} a{" "}
                      {dateRender(item["DATA_FIM"])}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
}
